<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">Responsabilidades</div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Responsabilidades dos Agentes"
          message="Preciso de ajuda no cadastro de responsabilidades dos agentes."
        >
          <p>
            Essa página gerencia as responsabilidades dos agentes de tratamento de dados, denominados também de controladores e operadores.
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Acesso fácil às Responsabilidades (LGPD Art. 9, inciso VI)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  <b>Art. 9º:</b> O titular tem direito ao acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva acerca de, entre outras características previstas em regulamentação para o atendimento do princípio do livre acesso:
                </p>
                <p>...</p>
                <p>
                  <b>VI</b> - responsabilidades dos agentes que realizarão o tratamento; e
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn
          color="success"
          @click="openResponsibilityDialog()"
        >
          Nova Responsabilidade
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="responsabilities"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhuma responsabilidade cadastrada"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openResponsibilityDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              deleteDialog = true;
              responsibility_id = item.id;
            "
          >
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog
      v-model="responsibilityDialog"
      max-width="800"
      :persistent="loadingSave"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="responsibilityForm.id">Edição de Responsabilidade</div>
          <div v-else>Nova Responsabilidade</div>
          <div>
            <v-btn
              icon
              @click="responsibilityDialog = false"
              ><v-icon color="black">mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form ref="form" @submit.prevent="saveResponsibility()">
            <v-text-field
              v-model="responsibilityForm.title"
              label="Titulo *"
              outlined
              hide-details="auto"
              color="black"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
              <v-textarea
                v-model="responsibilityForm.description"
                label="Descrição *"
                outlined
                hide-details="auto"
                color="black"
                class="my-3"
                :rules="[$rules.required]"
                :disabled="loadingSave"
              />
            <v-card-actions class="pt-3 pb-0 pr-0">
              <v-spacer/>
              <v-btn
                color="secondary"
                class="mr-1" text
                @click="responsibilityDialog = false"
                :disabled="loadingSave"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="success"
                type="submit"
                :loading="loadingSave"
              >
                <template v-if="responsibilityForm.id">
                  Salvar
                </template>
                <template v-else>
                  Criar
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir essa Responsabilidade?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="mr-1"
            text
            :disabled="loadingDelete"
            @click="deleteDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            class="ml-1"
            :loading="loadingDelete"
            color="error"
            text
            @click="deleteResponsibility()"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Responsabilities",
  
  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingDelete: false,
    responsibilityDialog: false,
    deleteDialog: false,
    loadingSave: false,
    responsibilityForm: {
      title: "",
      description: "",
    },
    headers: [
      {
        text: "Titulo",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "title",
      },
      { text: "Descrição", value: "description" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    responsabilities: [],
  }),
  mounted() {
    this.getResponsabilities();
  },
  methods: {
    saveResponsibility() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if (this.responsibilityForm.id) {
          request = this.$axios.put(
            `/responsibility/${this.responsibilityForm.id}`,
            this.responsibilityForm
          );
        } else {
          request = this.$axios.post("/responsibility", this.responsibilityForm);
        }
        request
          .then((response) => {
            this.$showMessage("success", "Responsabilidade Salva");
            this.getResponsabilities();
            this.responsibilityDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                this.$showMessage("error", "Responsabilidade não encontrado");
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    deleteResponsibility() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/responsibility/${this.responsibility_id}`)
        .then((response) => {
          this.$showMessage("success", "Responsabilidade Excluida");
          this.getResponsabilities();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Responsabilidade não encontrado");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getResponsabilities() {
      this.loading = true;
      this.$axios
        .get("/responsibilities")
        .then((response) => {
          this.responsabilities = response.data;
        })
        .catch((error) => {
          this.$showMessage(
            "error",
            "Erro inesperado no carregamento das responsabilidades"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /* resetForm(){
      this.$refs.form.reset()
    }, */
    openResponsibilityDialog(responsibility) {
      if (responsibility) {
        this.responsibilityForm = Object.assign({}, responsibility);
      } else {
        this.responsibilityForm = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
      this.responsibilityDialog = true;
    },
  },
};
</script>